var RoomStatus;
(function (RoomStatus) {
    RoomStatus[RoomStatus["SETUP"] = 0] = "SETUP";
    RoomStatus[RoomStatus["PLAYING"] = 1] = "PLAYING";
    RoomStatus[RoomStatus["WHITE"] = 2] = "WHITE";
    RoomStatus[RoomStatus["BLACK"] = 3] = "BLACK";
    RoomStatus[RoomStatus["REMIS"] = 4] = "REMIS";
    RoomStatus[RoomStatus["ABORTED"] = 5] = "ABORTED";
    RoomStatus[RoomStatus["REMIS_REQUESTED"] = 6] = "REMIS_REQUESTED";
})(RoomStatus || (RoomStatus = {}));
export default RoomStatus;
