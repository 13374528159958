export default class ChessPiece {
    constructor(row, column, color, moved) {
        this.column = column;
        this.row = row;
        this.color = color;
        this.moved = moved;
    }
    move(movePosition, board) {
        const boardCopy = [...board];
        if (this.validMoves(boardCopy).filter((validMove) => validMove.row === movePosition.row &&
            validMove.column === movePosition.column).length > 0) {
            boardCopy[this.row][this.column] = null;
            this.row = movePosition.row;
            this.column = movePosition.column;
            boardCopy[movePosition.row][movePosition.column] = this;
        }
        this.moved += 1;
        return boardCopy;
    }
    testMove(movePosition, board) {
        const boardCopy = [...board];
        boardCopy[this.row][this.column] = null;
        this.row = movePosition.row;
        this.column = movePosition.column;
        boardCopy[movePosition.row][movePosition.column] = this;
        return boardCopy;
    }
    toString() {
        return `${this.row}:${this.column} - ${this.color}`;
    }
}
